import React from 'react';
import TshirtPic from '../Assets/tshirt.jpg';
import './ClothingHeading.css';

import print1 from './media/clothes1.jpg';
import print2 from './media/clothes2.jpg';
import print3 from './media/clothes3.jpg';
const ClothingHeading = () => {
    const goTo = () => {
        window.location.href = '/clothing';
    };

    //get width of screen
    const width = window.innerWidth;
    console.log(width)

    return (
        <div className='imageTextContainer' onClick={goTo} style={{ cursor: 'pointer' }}>
            <div className='imageContainer'>
                <img src={print1} alt="Sample" className='responsiveImage' />
                <img src={print3} alt="Sample" className='responsiveImage' />

                {width > 800 && <img src={print2} alt="Sample" className='responsiveImage' />}

            </div>
            <div className='textContainer'>
                <p>Shop Clothing Here</p>
            </div>
        </div>
    );
};
export default ClothingHeading;