import React, { useState, useEffect } from 'react';
import Product from './Product';
import './ProductLists.css';

const ProductList = ({ type }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://k8dbmanager.andrewdouglas004.workers.dev/', {
                    method: 'POST',
                    body: JSON.stringify({
                        message: 'download',
                        type: type,
                    }),
                });

                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [type]); // Add type to the dependency array

    return (
        <div className='productLists'>
            <ul>
                {products.map((product) => (
                    <Product key={product.id} product={product} />
                ))}
            </ul>
        </div>
    );
};

export default ProductList;