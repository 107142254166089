import React from 'react';
import './Nav.css';

import HeaderP from './HeaderP.png';

function Nav() {
    const goToHome = () => {
        window.location.href = '/';
    };

    return (
        <nav>
            {/* <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul> */}
            <img src={HeaderP} alt='header' onClick={goToHome} />
        </nav>
    );
}

export default Nav;