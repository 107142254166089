import React from 'react';
import "./ShopTheDrop.css";

function Header() {
    const goToClothing = () => {
        window.location.href = '/clothing';
    };

    return (
        <header className="shopTheDrop" onClick={goToClothing}>
            {/* <h1>Shop the Drop</h1> */}
        </header>
    );
}

export default Header;