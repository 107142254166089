import React from 'react';
import './PageNav.css';

const PageNav = () => {
    return (
        <div className='pageNav'>
            <ul>
                <li><a href="/clothing">Clothing</a></li>
                <li><a href="/paintings">Paintings</a></li>
                <li><a href="/prints">Prints</a></li>
            </ul>
        </div>
    );
};

export default PageNav;