import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './Pages/Home';
import ProductDetail from './CoreComponents/ProductDetail';
import GoodPurchase from './Pages/PurchaseGood/GoodPurchase';
import ClothingPage from './Pages/Clothing';
import PaintingsPage from './Pages/Paintings';
import PrintsPage from './Pages/Prints';

import TermsAndConditions from './Pages/terms/termsandconditions';

import Privacy from './Pages/terms/Privacy';

import About from './Pages/About/About';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/goodpurchase" element={<GoodPurchase />} />

        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/paintings" element={<PaintingsPage />} />
        <Route path="/prints" element={<PrintsPage />} />
        <Route path="/clothing" element={<ClothingPage />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;