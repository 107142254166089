import React from 'react';
import "./About.css";
import aboutImage from './aboutImage.JPG';
import Footer from '../../Visual/Footer';
import Nav from '../../Visual/Nav';

const About = () => {
    return (
        <div className='aboutBase'>
            <Nav />
            <div className='aboutContent'>
                <div className='aboutImage'>
                    <img src={aboutImage} alt="About" />
                </div>  
                <div className='aboutText'> 
                    <h1>About K8</h1>
                    <p>Hi! Welcome to my website K8. My name is Kate I am 20 years old, from Coventry but now studying nursing in Edinburgh, a beautiful city. 
                        <br></br>                <br></br>
                        I am launching K8 art as a self-taught artist who solely paints, but with a vision of creating a sustainable line of clothes.
                        Painting on 100% cotton fabrics and sewing onto upcycled pieces, each painting and garment is a unique one-of-a-kind piece. 
                        My aim is to have a drop of these clothes once every other month with new and different paintings, mainly inspired by nature, florals and botanics. 
                        Please follow along on my socials to see the gallery of pieces grow!
                        <br></br>                <br></br>
                        I grew up with the sport of horse riding. The opportunity to grow alongside the beauty of horses and the nature of them has been inspiring. 
                        Painting has been something I have continued through my life, and now starting up K8 is something I'm so excited for!
                    </p>
                </div>  
            </div>
            <Footer />
        </div>
    );
};

export default About;