import React from 'react';
import TshirtPic from '../Assets/tshirt.jpg';
import './ClothingHeading.css';

import print1 from './media/paintings1.jpg';
import print2 from './media/painting2.jpg';
import print3 from './media/paintings3.jpg';

const PaintingHeading = () => {
    const goTo = () => {
        window.location.href = '/paintings';
    };

    const width = window.innerWidth;

    return (
        <div className='imageTextContainer' onClick={goTo} style={{ cursor: 'pointer' }}>
            <div className='imageContainer'>
                <img src={print2} alt="Sample" className='responsiveImage' />
                <img src={print1} alt="Sample" className='responsiveImage' />
                {width > 800 && <img src={print3} alt="Sample" className='responsiveImage' />}
            </div>
            <div className='textContainer'>
                <p>Shop Paintings Here</p>
            </div>
        </div>
    );
};

export default PaintingHeading;