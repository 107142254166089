import React from 'react';

import "./TermsAndConditions.css";

const TermsAndConditions = () => {
    return (
        <div className='tasBase'>
            <h1>Terms and Conditions</h1>
            <br></br>
            <h2>Returns</h2>
            <p>In order to return a product please email hello@k8arts.com</p>
            <p>Products can be returned within 14 days of purchase provided the clothes are unworn and in original condition.</p>
            <p>Buyer will pay return shipping.</p>
            <p>Refunds will be issued once the product is received and inspected.</p>
            <br></br>
            <h2>Shipping</h2>
            <p>Shipping is calculated at checkout.</p>
            <p>Shipping is available to the UK and Europe.</p>
            <p>Shipping rates vary depending on the item.</p>
            <p>Items will be dispatched within 2 working days of the order being placed.</p>
            <br></br>
            <h2>Sales</h2>
            <p>Items are sold on a first come first serve basis.</p>
            <p>Items are not reserved until payment is received.</p>
            <p>We reserve the right to cancel any orders and refund the money.</p>
        </div>
    );
};

export default TermsAndConditions;