import React from "react";
import ProductList from "../CoreComponents/ProductList";
import Nav from "../Visual/Nav";
import "./Home.css";

import Footer from "../Visual/Footer";

import ClothingHeading from "./Components/ClothingHeading";
import PaintingHeading from "./Components/PaintingHeading";
import PrintsHeading from "./Components/PrintsHeading";

import mainImage from './HomeComponents/mainImage.png';

import secondary from './HomeComponents/secondary.png';

import ShopTheDrop from "./HomeComponents/ShopTheDrop";

import Welcome from "./HomeComponents/Welcome";

function HomePage() {
  return (
    <div className="HomeBase">
            <Nav />
      <ShopTheDrop />
      <img src={mainImage} alt="Shop the Drop" className="firstImage" />
      <ClothingHeading />
      <PaintingHeading />
      <PrintsHeading />
      <img src={secondary} alt="Shop the Drop" className="secondImage" />
      <Footer />
    </div>
  );
}

export default HomePage;
