import React from 'react';
import { Link } from 'react-router-dom';
import "./Product.css";

const Product = ({ product }) => {

    const isSoldOut = product.stock == 0;
    return (
        <Link to={`/product/${product.UUID}`} state={{ product }} className={`actualproduct ${isSoldOut ? 'soldOut' : ''}`}>
                        <img src={product.img1[0].url} alt={product.name} loading='lazy'/>
                        <h2>{product.name}</h2>
            <p className={isSoldOut ? 'soldOutText' : ''}>{isSoldOut ? 'Sold Out' : `£${product.price}`}</p>
        </Link>
    );
};

export default Product;