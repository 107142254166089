import React from 'react';
import './GoodPurchase.css';
import HeaderP from './HeaderP.png';

function GoodPurchase() {
    return (
        <div className='goodPurchaseBase'>
            <div className='goodPurchaseHeader'> 
                <img src={HeaderP} alt='header' />
                <h1>Thank you for purchasing from K8 Art</h1>
                <p>Your item will be dispatched within 48 hours.</p>
                <p>You will receive an email confirmation shortly.</p>
                <p>If you have any questions, please contact us at <a href="mailto:hello@k8arts.com">Email</a></p>
            </div>
        </div>
    );
}

export default GoodPurchase;