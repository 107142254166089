import React from 'react';

import "./Footer.css";

const Footer = () => {

    const handleTermsClick = () => {
        window.location.href = '/termsandconditions';
    }

    const handleEmailClick = () => {
        window.location.href = 'mailto:hello@k8arts.com'
    }

    const handleAbout = () => {
        window.location.href = '/about';
    }

    const handleprivacy = () => {
        window.location.href = '/privacy';
    }

    return (
        <footer>
            <ul>
                <li onClick={() => handleTermsClick()}>Terms</li>
                <li onClick={() => handleAbout()}> About</li>
                <li onClick={() => handleEmailClick()}>Email</li>
                <li onClick={() => handleprivacy()}> Privacy </li>
            </ul>
        </footer>
    );
};

export default Footer;