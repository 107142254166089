import React from 'react';

import "./TermsAndConditions.css";

const Privacy = () => {
    return (
        <div className='tasBase'>
            <h1>Privacy Policy</h1>
            <br></br>
            <p> No data is collected until a purchase is made.</p>
            <p> Data is collected for the purpose of fulfilling the order.</p>
            <p> Data is collected via Stripe.</p>
            <p> Data is stored securely and is not used for any other purpose.</p>
            <p> For more information please contact hello@k8arts.com </p>
        <p>For more information, please refer to <a href="https://stripe.com/privacy">Stripe's data policy</a>.</p>
        </div>
    );
};


export default Privacy;