import React from "react";
import ProductList from "../CoreComponents/ProductList";
import "./ProductPages.css";
import Nav from "../Visual/Nav";

import Footer from "../Visual/Footer";

import ClothingBanner from "./Assets/ClothingBanner.png";

import PageNav from "../Visual/PageNav";

import mainImage from './HomeComponents/mainImage.png';

function PaintingsPage() {
  return (
    <div className="productPage">
      <Nav />
      <PageNav />
      <img src={mainImage} alt="Clothing Banner" className="banner"/>
      <div className="productPageLists">
        <ProductList type={"Paintings"} />
      </div>
      <Footer />
    </div>
  );
}

export default PaintingsPage;