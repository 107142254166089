import React, { useState, useEffect } from 'react';

const ProductImageViewer = ({ images }) => {
    if (images.length !== 3) {
        throw new Error('ProductImageViewer requires exactly 3 images.');
    }

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    const handleThumbnailClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="product-image-viewer">
            <img src={images[currentIndex].url} alt="Product" className="product-image" />
            <div className="thumbnail-strip">
                {images.map((image, index) => (
                    index !== currentIndex && (
                        <img
                            key={index}
                            src={image.url}
                            alt={`Thumbnail ${index + 1}`}
                            className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => handleThumbnailClick(index)}
                        />
                    )
                ))}
            </div>
        </div>
    );
};

export default ProductImageViewer;